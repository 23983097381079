<template>
    <div class="ReDetails">
        <div class="detail">
            <div>订单预览</div>
            <van-notice-bar wrapable :scrollable="false">
                (流量增加，使用时间不变，加油包分月包、年包，请根据需要充值加油包)
            </van-notice-bar>
        </div>
        <div class="detailMain">
            <div class="title">* 充值前</div>
            <div class="main">
                <div class="mainTitle">
                    <van-button plain type="info" size="small">套餐详细</van-button >
                </div>
                <div class="mainCard">
                    <div><van-tag>总剩余流量：{{arrs.periodCanUsage - arrs.usageAmount}}M</van-tag></div>
                    <div class="mainCard_pack">{{arrs.packName}}(主套餐)</div>
                    <van-row gutter="20">
                        <van-col span="12">套餐可用量：{{arrs.periodCanUsage}}M</van-col>
                        <van-col span="12">套餐已用量：{{arrs.usageAmount}}M</van-col>
                    </van-row>
                    <div class="mainCard_red">套餐剩余量：{{arrs.periodCanUsage - arrs.usageAmount}}M</div>
                    <van-row gutter="20">
                        <van-col span="12">开始时间：{{arrs.periodStartTime}}</van-col>
                        <van-col span="12">结束时间：{{arrs.periodEndTime}}</van-col>
                    </van-row>
                </div>
                
            </div>

            <div class="titles">* 充值后</div>
            <div class="main">
                <div class="mainTitle">
                    <van-button plain type="info" size="small">套餐详细</van-button >
                </div>
                <div class="mainCard">
                    <div><van-tag>总剩余流量：{{arrs.periodCanUsage - arrs.usageAmount + (pack.flow * pack.period)}}M</van-tag></div>
                    <div class="mainCard_pack">{{arrs.packName}}(主套餐)</div>
                    <van-row gutter="20">
                        <van-col span="12">套餐可用量：{{arrs.periodCanUsage}}M</van-col>
                        <van-col span="12">套餐已用量：{{arrs.usageAmount}}M</van-col>
                    </van-row>
                    <div class="mainCard_red">套餐剩余量：{{arrs.periodCanUsage - arrs.usageAmount}}M</div>
                    <van-row gutter="20">
                        <van-col span="12">开始时间：{{arrs.periodStartTime}}</van-col>
                        <van-col span="12">结束时间：{{arrs.periodEndTime}}</van-col>
                    </van-row>
                    <van-divider />
                    <div class="mainCard_pack">{{pack.packName}} X {{pack.period}}</div>
                    <van-row gutter="20">
                        <van-col span="12">套餐可用量：{{pack.flow}}M</van-col>
                        <van-col span="12">套餐已用量：0M</van-col>
                    </van-row>
                    <div class="mainCard_red">套餐剩余量：{{pack.flow}}M</div>
                    <van-row gutter="20">
                        <van-col span="12">开始时间：{{pack.startDate}}</van-col>
                        <van-col span="12">结束时间：{{pack.endDate}}</van-col>
                    </van-row>
                </div>
            </div>
        </div>
        <div class="continue">
            <van-button type="primary" @click="toIndex()">继续查询</van-button>
        </div>
    </div>
</template>
<script>
export default {
    name:'refueingDetail',
    data() {
        return {
            arrs:{}
        }
    },
    methods: {
        submit(){
            
        },
        toIndex(){
                this.$router.push({name:'Home'});
        }
    },
    created() {
        this.$store.commit('setPageTitle', "订单预览");
        this.arrs = JSON.parse(localStorage.message)
        if(!localStorage.refueing){
            this.$toast.fail("未发现订单");
            this.$router.push({name:'refueingCharge'})
        }else{
            this.pack = JSON.parse(localStorage.refueing)
        }
       
    }
}
</script>
<style lang="scss" scoped>
.ReDetails{
    text-align: left;padding-bottom:60px;
    .detail{
        width: 80%;padding: 30px 10% ;
        div:first-child{
            font-size: 45px;font-weight: 600;margin-bottom: 10px;
        }
    }
    .detailMain{
        width: 80%;margin: 0 auto;
        .title{
            font-size: 24px;color: #1F9DF1;
        }
        .titles{
            margin-top: 50px;font-size: 24px;color: #1F9DF1;
        }
        .main{
            border: 1px solid #ddd;margin-top: 10px;
        }
        .mainTitle{
            text-align: center;margin-top:20px; 
        }
        .mainCard{
            padding: 26px;font-size: 24px;
        }
        .mainCard_pack{
            font-weight: 600;margin: 10px 0;font-size: 32px;
        }
        .van-row{
            margin: 10px 0 !important;
        }
        .mainCard_red{
            color: red;font-weight: 600;
        }
    }
}

 .continue{
     .van-button--primary{
         border-radius: 80px;
         width: 5rem;
         color:white;
     }
     text-align: center;
     padding-top: 0.5rem;
 }


</style>
    
